<script>
/**
 * Панель с деталями Кассы
 *
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
import {isEmpty, groupBy, sumBy, map} from "lodash"
import IncomeTimeline from "@/views/courier/cashbox/IncomeTimeline";
import CourierCashbox from "@/models/CourierCashbox";

export default {
    name: "DetailsPanel",
    components: {IncomeTimeline},
    events: [
        /**
         * @event confirm Подтверждение Кассы
         * @param {CourierCashbox} $cashbox
         * */
        'confirm'
    ],
    props: {
        cashbox: {
            type: CourierCashbox,
            required: true
        }
    },
    data() {
        return {
            details: {}
        }
    },
    computed: {
        isConfirmable: vm => vm.cashbox.isConfirmable(),
        isConfirmed: vm => vm.cashbox.isConfirmed(),
        isActive: vm => vm.cashbox.isActive(),
        isEmptyText: vm => isEmpty(vm.details),
        payflows: vm => {
            const groupedByType = vm.details.incomes && groupBy(vm.details.incomes, 'typeText');
            return map(groupedByType, (group, type) => ({
                type,
                sum_total: sumBy(group, 'amount')
            }));
        }
    },
    watch: {
        cashbox() {
            this.fetchDetails();
        }
    },
    methods: {
        async fetchDetails() {
            this.details = await this.cashbox.fetchDetails();
        },

        async onConfirmClick() {
            try {
                await this.$confirm('Платежи будут зачислены в историю. Продолжить?', 'Подтвердить');
                await this.cashbox.confirm();
                this.$notify.success('Информация по кассе учтена');
                this.$emit('confirm', this.cashbox);
            } catch (e) {
                this.$notify.error(e.getFirstErrorMesage() || 'Ошибка запроса');
            }
        }
    },
    mounted() {
        this.fetchDetails();
    }
}
</script>


<template>
    <el-drawer :modal="false"
               direction="rtl"
               v-bind="$attrs"
               size="40%"
               v-on="$listeners">
        <template slot="title" v-if="isConfirmable">
            <div>
                <el-button type="primary"
                           class="confirm-button"
                           icon="el-icon-sell"
                           @click="onConfirmClick">Подтвердить все платежи
                </el-button>
            </div>
        </template>

        <div class="panel"
             v-loading="cashbox.loading">
            <p v-if="isEmptyText" class="empty-text">Нет данных для отображения ;(</p>

            <div v-if="!isEmptyText && (isConfirmable || isActive)">
                <ul class="payflows">
                    <li v-for="flow in payflows"
                        class="payflows__item">
                        <span class="payflows__label">{{ flow.type }}</span>
                        <span class="payflows__price">{{ $Formatter.asCurrency(flow.sum_total) }}</span>
                    </li>
                </ul>
                <income-timeline :store="details.incomes"></income-timeline>
            </div>

            <div v-if="!isEmptyText && isConfirmed">
                <p>{{ cashbox.statusText }} {{ $Formatter.asDateTime(cashbox.updated_at) }}</p>
            </div>

        </div>
    </el-drawer>
</template>


<style scoped lang="scss">
@import "src/scss/vars";

.panel {
    padding: 0 $default-gap;
}

.payflows {
    display: flex;
    align-items: flex-end;
    list-style: none;
    padding: 0;
    width: 100%;
    border-bottom: 1px dashed $color-borders;
    margin-bottom: 2rem;
    padding-bottom: 1rem;

    &__item {
        flex: 0 0 auto;
        text-align: center;
        padding: 0 $default-gap/2;
        //border: 1px solid silver;
        width: 33%;
    }

    &__label {
        font: 12px/1 $font-family-base;
    }

    &__price {
        display: block;
        white-space: nowrap;
        font: bold 18px/2 $font-family-base;
    }
}

.confirm-button {
    width: 250px;
}
</style>
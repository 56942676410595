<script>
    import MainHeader from "../AppHeader";
    import MainFooter from "../AppFooter";

    export default {
        name: 'Main',

        components: {
            MainHeader,
            MainFooter
        },

        computed: {
            defaultActive: function () {
                return this.$router.currentRoute.name;
            }
        },
        beforeRouteEnter(to, from, next) {
            // autoredirect to first tab
            if (to.name.indexOf("courier-") < 0) {
                // use next with callback to prevent "uncaught error undefined" by calling next twice
                next(vm => {
                    vm.$router.push({
                        name: 'courier-list',
                        params: to.params,
                        replace: true
                    });
                });
            } else {
                next();
            }
        }
    }
</script>


<template>
    <el-container id="viewport" direction="vertical">
        <main-header></main-header>
        <el-container>
            <el-aside width="250px">
                <h1 class="aside-header">Курьеры</h1>
                <el-menu :router="true" :default-active="defaultActive">
                    <el-menu-item index="courier-list" :route="{name:'courier-list'}">
                        <el-icon class="el-icon-truck"></el-icon>
                        Все курьеры
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
        <main-footer></main-footer>
    </el-container>
</template>


<style scoped lang="scss">
    @import "../../scss/element";

    .el-aside {
        border-right: 1px solid #eeeeee;
        padding-top: 20px;
    }

    .el-menu {
        border-right: none;

        &-item {
            &.is-active {
                font-weight: 700;
                background-color: $--dropdown-menuItem-hover-fill;
            }
        }
    }
</style>
<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */

import Courier from "../../../models/Courier";
import AccountReference from "@/views/courier/card/AccountReference";

export default {
    name: "CourierCardMain",
    components: {AccountReference},
    props: {
        id: Number | String
    },
    data() {
        return {
            model: new Courier(),
            // Флаг состояния готовности загрузки данных Модели
            isModelReady: false
        }
    },
    watch: {
        $route: function (route) {
            // console.log(route);
            // update data only when model`s id changed
            if (route.params.id !== this.model.id) {
                this.prepareData(route.params.id);
            }
        }
    },
    computed: {
        defaultActive: vm => vm.$route.name,
        headerContent: vm => vm.model.$.shortname,
    },
    methods: {
        async prepareData(id) {
            this.isModelReady = false;
            this.model.set("id", id);
            try {
                await this.model.fetch({
                    params: {
                        expand: 'accountName'
                    }
                });
                this.isModelReady = true;
            } catch (e) {
                this.$message.error("Ошибка загрузки данных.");
            }
        },
        onBackClick() {
            this.$router.push({name: 'courier-list'});
        }
    },
    created() {
        // загружаем данные, когда представление создано
        // и данные реактивно отслеживаются
        this.prepareData(this.id);
    },
}
</script>


<template>
    <div v-loading="model.loading">
        <h1>
            <el-page-header title=""
                            @back="onBackClick">
                <template v-slot:content>
                    {{ headerContent }},
                    <account-reference :model="model"></account-reference>
                </template>
            </el-page-header>
        </h1>
        <div>
            <el-menu :router="true" :default-active="defaultActive" mode="horizontal">
                <el-menu-item index="courier-edit" :route="{name:'courier-edit',params:{model}}">
                    Основное
                </el-menu-item>
                <el-menu-item index="courier-cashbox" :route="{name:'courier-cashbox',params:{model}}">
                    Касса
                </el-menu-item>
            </el-menu>
            <router-view :model="model"
                         v-if="isModelReady"></router-view>
        </div>


    </div>
</template>


<style lang="scss" scoped>
.el-menu {
    margin: 20px 0;
}
</style>
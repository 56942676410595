/**
 * Модель данных: касса Курьера
 *
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";
import axios from "axios";

export default class CourierCashbox extends BaseModel {
    static entityName = 'cashbox';

    static STATUS_ACTIVE = 'active';
    static STATUS_TRANSFER = 'transfer';
    static STATUS_PARTIAL_CONFIRMED = 'partial_confirmed';
    static STATUS_CONFIRMED = 'confirmed';

    defaults() {
        return {
            id: null,
            courier_id: null,
            summary: 0.00,
            created_at: null,
            updated_at: null,
            status: null
        }
    }


    /**
     * @return {Boolean} Можно ли считать Кассу доступной для подтверждения
     * */
    isConfirmable() {
        return [CourierCashbox.STATUS_TRANSFER, CourierCashbox.STATUS_PARTIAL_CONFIRMED].indexOf(this.status) > -1;
    }


    /**
     * @return {Boolean} Можно ли считать Кассу подтвержденной
     * */
    isConfirmed() {
        return this.status === CourierCashbox.STATUS_CONFIRMED;
    }


    /**
     * @return {Boolean} Можно ли считать Кассу активной
     * */
    isActive() {
        return this.status === CourierCashbox.STATUS_ACTIVE;
    }


    /**
     * Подтверждение всей кассы от Курьера
     * @return {OrderPayment} Созданный Платеж
     * */
    async confirm() {
        let response = await axios.post(`courier/admin/cashbox/${this.id}/confirm`);
        return response.data;
    }


    /**
     * Fetch details for cashbox
     * */
    async fetchDetails() {
        let response = await axios.get(`courier/admin/cashbox/${this.id}`);
        return response.data;
    }
}

<script>
/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */

export default {
    name: "IncomeTimeline",
    props: {
        store: {
            type: Array,
            require: true
        }
    },
    methods: {
        onForwardClick(record) {
            this.$router.push({
                name: 'order-balance',
                params: {
                    id: record.order_id
                }
            })
        }
    },
}
</script>


<template>
    <el-timeline>
        <el-timeline-item v-for="record in store"
                          :key="record.id"
                          :timestamp="$Formatter.asDateTime(record.created_at)"
                          type="info">

            <div class="income">
                <div class="income__income">
                    <div class="income__amount">+ {{ $Formatter.asCurrency(record.amount) }}</div>
                    <div class="income__type">{{ record.typeText }}</div>
                </div>
                <div class="income__details">
                    <div class="income__order">
                        <el-popconfirm title="Перейти в карточку заказа?"
                                       confirm-button-text="Перейти"
                                       cancel-button-text="Отмена"
                                       @confirm="onForwardClick(record)">
                            <a class="action-link" slot="reference">Заказ №{{ record.orderNumber }}</a>
                        </el-popconfirm>
                    </div>
                    <div class="income__notes">{{ record.comment }}</div>
                </div>
            </div>


        </el-timeline-item>
    </el-timeline>
</template>


<style scoped lang="scss">
@import "src/scss/vars";

.income {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &__income {
        flex: 0 0 160px;
        text-align: center;
    }

    &__amount {
        white-space: nowrap;
        font: 16px/1.2 $font-family-base;
    }

    &__type {
        font: italic 12px/1.5 $font-family-base;
        color: $color-primary-light;
    }
}
</style>
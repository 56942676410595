<script>
/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
import Courier from "@/models/Courier";
import CashboxListItem from './ListItem';
import LazyList from "@/components/LazyList";
import BaseStore from "@/models/BaseStore";
import DetailsPanel from "@/views/courier/cashbox/DetailsPanel";
import CourierCashbox from "@/models/CourierCashbox";


export default {
    name: "CashboxMain",
    components: {DetailsPanel, LazyList, CashboxListItem},
    props: {
        model: {
            type: Courier,
            require: true
        }
    },
    data() {
        return {
            store: new BaseStore([], {
                model: CourierCashbox,
                fetchUrl: 'courier/admin/cashbox',
                filters: [
                    {
                        property: "courier_id",
                        value: this.model.id
                    }
                ],
                internalSorters: [
                    {property: 'status', direction: BaseStore.SORT_DESC},
                    {property: 'updated_at'}
                ],
                fetchParams: {
                    expand: 'statusText'
                }
            }),
            isOpened: false,
            selectedItem: null
        }
    },
    methods: {
        /**
         * @param {CourierCashbox} $model
         * */
        onItemClick($model) {
            // prevent panel opened on reload store
            if (!$model) {
                return;
            }
            this.selectedItem = $model;
            this.isOpened = true;
        },

        /**
         * Ус
         * @param {CourierCashbox} $model
         * */
        onCashboxConfirm($model) {
            this.isOpened = false;
            this.store.remove($model);
        }
    }
}
</script>


<template>
    <div class="main humanize-container">

        <lazy-list :store="store"
                   :filterable="false"
                   @rowselect="onItemClick">
            <template v-slot:item="{record}">
                <cashbox-list-item :model="record"></cashbox-list-item>
            </template>
        </lazy-list>

        <details-panel :visible.sync="isOpened"
                       v-if="selectedItem"
                       :cashbox="selectedItem"
                       @confirm="onCashboxConfirm"></details-panel>
    </div>
</template>


<style scoped lang="scss">
.main {
    //height: 100%;
    overflow: auto;
}
</style>
<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import Courier from "../../models/Courier";
    import BaseForm from "../../components/Form";

    export default {
        name: "Form",
        components: {
            BaseForm
        },
        props: {
            id: Number | String,
            model: {
                type: Courier,
                default: () => new Courier()
            }
        },
        data() {
            return {
                Courier: Courier,
                title: this.model.id ? '' : 'Добавить курьера'
            }
        },
        methods:{
            async prepareData() {
                this.model.set("id", this.$route.params.id);
                try {
                    await this.model.fetch();
                } catch (e) {
                    this.$message.error("Ошибка загрузки данных.");
                }
            }
        }
    }
</script>


<template>
    <div class="humanize-container" v-loading="model.loading">
        <base-form :title="title"
                   backTitle=""
                   :model="model">

            <el-form-item label="Краткое имя"
                          :error="model.errors.shortname"
                          :show-message="!!model.errors.shortname"
                          required>
                <el-input v-model="model.shortname"
                          placeholder="Иваныч"
                          clearable
                          :maxlength="64"></el-input>
            </el-form-item>
            <el-form-item label="Полное имя (ФИО)"
                          :error="model.errors.fullname"
                          :show-message="!!model.errors.fullname"
                          required>
                <el-input v-model="model.fullname"
                          placeholder="Иванов Иван Иваныч"
                          clearable
                          :maxlength="128"></el-input>
            </el-form-item>

            <el-row :gutter="20">
                <el-col :md="8">
                    <el-form-item label="Email"
                                  :error="model.errors.email"
                                  :show-message="!!model.errors.email">
                        <el-input v-model="model.email"
                                  clearable
                                  :maxlength="64"
                                  placeholder="ivanov@gmail.com"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :md="8">
                    <el-form-item label="Телефон"
                                  :error="model.errors.phone"
                                  :show-message="!!model.errors.phone">
                        <el-input v-model="model.phone"
                                  clearable
                                  :maxlength="16"
                                  placeholder="+71234567890"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item label="Примечания"
                          :error="model.errors.notes"
                          :show-message="!!model.errors.notes">
                <el-input type="textarea"
                          v-model="model.notes"
                          :maxlength="255"
                          :show-word-limit="true"
                          placeholder="Небольшой текст описания для заметок..."
                          :autosize="{ minRows: 2}"></el-input>
            </el-form-item>
        </base-form>
    </div>
</template>


<style scoped lang="scss">
</style>
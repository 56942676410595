<script>
    /**
     * The list of Products
     *
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import compact from "lodash/compact";
    import Courier from "../../models/Courier";
    import BaseStore from "../../models/BaseStore";
    import LazyList from "../../components/LazyList";


    export default {
        name: "CourierList",

        components: {
            LazyList
        },


        data() {
            return {
                store: new BaseStore([], {
                    model: Courier,
                    fetchParams: {
                        sort: '-shortname',
                        filterText: ""
                    }
                }),
                selectedModels: [],
                filterText: "",
                needData: null
            }
        },

        methods: {
            getMetaString(model) {
                return compact([
                    model.email,
                    model.phone,
                    model.notes
                ]).join(", ");
            }
        }
    }
</script>


<template>
    <div class="humanize-container">
        <lazy-list :store="store"
                   nameProp="shortname"
                   filter-placeholder="Поиск: имя, фамилия, телефон, email ..."
                   :get-meta-string="getMetaString"
                   create-route="courier-create"
                   details-route="courier-edit"></lazy-list>
    </div>
</template>


<style lang="scss" scoped>
    .metas {
        font-size: .8rem;
        color: gray;
    }
</style>
<script>
import Courier from "@/models/Courier";
import axios from "axios";
import {parseResponseErrorMessage} from "@/plugins/axios";

/**
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */
export default {
  name: "AccountReference",
  props: {
    model: {
      type: Courier,
      required: true
    }
  },
  data() {
    return {
      refDialogVisible: false,
      formData: {
        email: this.model.$.email
      },
      rules: {
        email: [
          {required: true, type: 'email'},
        ]
      },
      isLoading: false
    }
  },
  computed: {
    accountName: vm => vm.model.$.accountName,
    accountRefText: vm => vm.accountName ? `@${vm.accountName}` : 'аккаунт не связан',
    accountRefTriggerClass: vm => ({'account-ref--no-ref': !vm.accountName})
  },
  watch: {
    refDialogVisible() {
      Object.assign(this.formData, {email: this.model.$.email});
    },
  },
  methods: {
    onSubmitClick() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }

        const data = {
          ...this.formData,
          courier_id: this.model.id
        }

        this.isLoading = true;
        axios.post('courier/admin/account/create', data).then((response) => {
          const accountName = response.data.accountName;
          const courierName = this.model.$.fullname;
          this.model.set('accountName', accountName || null);
          this.model.sync();
          this.refDialogVisible = false;
          this.$message.success(`${courierName} успешно связан с ${accountName}`);
        }).catch((err) => {
          this.$message.error(parseResponseErrorMessage(err));
        }).finally(() => {
          this.isLoading = false;
        });
      });
    },

    onUnlinkClick() {
      const data = {
        courier_id: this.model.id
      }
      this.isLoading = true;
      axios.post('courier/admin/account/drop', data).then(() => {
        const courierName = this.model.$.fullname;
        this.model.set('accountName', null);
        this.model.sync();
        this.refDialogVisible = false;
        this.$message.success(`${courierName} успешно отвязан`);
      }).catch((err) => {
        this.$message.error(parseResponseErrorMessage(err));
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }
}
</script>

<template>
  <div class="account-ref-ct">
    <a href="#"
       class="action-link account-ref"
       :class="accountRefTriggerClass"
       @click.prevent.stop="refDialogVisible=true"> {{ accountRefText }}</a>

    <el-dialog :visible.sync="refDialogVisible"
               append-to-body
               destroy-on-close
               title="Привязка к аккаунту">
      <div v-if="!accountName">
        <el-form ref="form" :rules="rules" :model="formData">
          <el-form-item label="Email учетной записи"
                        prop="email">
            <el-input v-model="formData.email"
                      clearable></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary"
                   icon="el-icon-link"
                   :loading="isLoading"
                   @click="onSubmitClick">Привязать
        </el-button>
      </div>
      <el-button v-else
                 icon="el-icon-close"
                 type="danger"
                 :loading="isLoading"
                 @click="onUnlinkClick">Отвязать от {{ accountName }}
      </el-button>
    </el-dialog>

  </div>
</template>


<style scoped lang="scss">
.account-ref-ct {
  display: inline-block;
}

.account-ref {
  font-size: smaller;

  &--no-ref {
    color: red;
  }
}
</style>
<script>
/**
 * View details of cashbox income
 *
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import CourierCashbox from "@/models/CourierCashbox";

export default {
    name: "CashboxListItem",
    props: {
        model: {
            type: CourierCashbox,
            required: true
        }
    }
}
</script>


<template>
    <div class="item" :class="[`item--${model.status}`]">
        <span class="item__price">{{ $Formatter.asCurrency(model.summary) }}</span>, <span class="item__status">{{ model.statusText }}</span>
        <span class="item__date">{{ $Formatter.asDate(model.updated_at, "LL") }}</span>
    </div>


</template>


<style lang="scss" scoped>
@import "../../../scss/vars";

.item {
    padding-bottom: $default-gap/2;

    & + & {
        border-top: 1px solid $color-borders;
        padding-top: $default-gap/2;
    }

    &__price {
        white-space: nowrap;
        font: bold 20px/1.5 $font-family-base;
        color: #000000;
    }


    &__status {
        font: italic 14px/1.2 $font-family-base;
        color: $color-notes;
    }

    &__date {
        display: block;
        font: 12px/1.2 $font-family-base;
        color: $color-notes;
    }
}

.item--confirmed {
    .item__price {
        color: $color-info;
    }
}

.item--transfer {
    .item__price {
        color: $color-danger;
    }
}
</style>